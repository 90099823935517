<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.liveResults') }}</h5>
                <Toast />
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="name" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown id="inOfferDropDown" style="width: 100%" v-model="inOfferModel" :options="inOfferOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.inOffer')" />
                            <label for="inOfferDropDown">{{ $t('dropdown.placeholder.inOffer') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-4 p-text-right"><Button :label="$t('buttons.getFixtures')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="offerNumber" />
                            <label>{{ $t('search.byOfferNumber') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="searchName" />
                            <label>{{ $t('search.byName') }}</label>
                        </span>
                    </div>
                </div>
                <transition-group name="p-message" tag="div" v-show="showNoEvents" class="p-col-12 p-mb-2">
                    <Message :key="errorKeyCount" :severity="'info'">{{ $t('sportsbets.alerts.noEventsToImport') }}</Message>
                </transition-group>
                <DataTable v-show="showFixturesTable" @page="onPage($event)" :value="items" :paginator="true" :rows="perPage" :loading="loading" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                    <template #header> </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :header="$t('sportsbets.inOffer')">
                        <template #body="{ data }">
                            <Button :icon="'pi pi-' + inOfferIcon(data.inOffer)" :class="'p-button-rounded p-button-' + inOfferColor(data.inOffer) + ' p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                    <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true">
                        <template #body="{ data }"> {{ data.offerNumber != 0 ? data.offerNumber : '' }} </template>
                    </Column>
                    <Column field="fixture.startDate" :header="$t('sportsbets.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.fixture.startDate) }} </template>
                    </Column>
                    <Column field="fixture.name" :header="$t('sportsSettings.name')" :sortable="true"> </Column>
                    <Column field="fixture.status" :header="$t('live.liveStatus')" :sortable="true">
                        <template #body="{ data }"> {{ decotLiveFixtureStatus(data.fixture.status) }} </template>
                    </Column>
                    <Column field="marketsNo" :header="$t('sportsSettings.marketsNo')" :sortable="true"> </Column>
                    <Column field="locationName" :header="$t('sportsbets.location')" :sortable="true"></Column>
                    <Column field="leagueName" :header="$t('sportsbets.league')" :sortable="true"></Column>
                    <Column>
                        <template #body="{ data }">
                            <Button :label="$t('buttons.showTickets')" class="p-button-text p-button-plain p-button-info p-mr-2 p-mb-2" @click="goto('sportBetsTickets', { backRoute: this.crtRoute, redirectFixtureId: data.fixtureId })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { LsportsApi } from '../../service/LsportsApi';
import { uiSettings } from '../../settings/uiSettings';

export default {
    name: 'LiveResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            LsportsApi: new LsportsApi(),
            startDateModel: null,
            endDateModel: null,
            crtPage: 1,
            perPage: uiSettings.tableRows,
            loading: true,
            sports: [],
            defaultSportsModel: { id: 0, name: 'All' },
            sportsModel: {},
            inOfferModel: { Name: 'All', Id: '0' },
            inOfferOptions: [
                { Name: 'All', Id: '0' },
                { Name: 'In Offer', Id: '1' },
                { Name: 'Out of Offer', Id: '2' },
            ],
            showFetchBtn: false,
            filtersInitializationDone: false,
            showFixturesTable: false,
            showNoEvents: false,
            errorKeyCount: 0,
            offerNumber: '',
            searchName: '',
            crtRoute: 'liveResults',
        };
    },
    computed: {
        startDateApi() {
            return this.formatApiDate(this.startDateModel);
        },
        endDateApi() {
            return this.formatApiDate(this.endDateModel);
        },
    },
    watch: {
        startDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = { id: 0, name: 'All' };
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        endDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = { id: 0, name: 'All' };
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        sportsModel() {
            if (this.filtersInitializationDone) {
                this.readItems();
            }
        },
        inOfferModel() {
            if (this.filtersInitializationDone) {
                this.readItems();
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';

        const tomorrow = new Date(crtDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let yearT = tomorrow.getFullYear();
        let monthT = tomorrow.getMonth() + 1;
        if (monthT <= 9) {
            monthT = '0' + monthT;
        }
        let dayT = tomorrow.getDate();
        if (dayT <= 9) {
            dayT = '0' + dayT;
        }
        var endDateString = yearT + '.' + monthT + '.' + dayT + ' 10:00';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);

        this.getSports();
    },
    methods: {
        readItems() {
            this.loading = true;
            this.showNoEvents = false;
            let searchOfferNumber = this.offerNumber;
            if (this.offerNumber == '') {
                searchOfferNumber = '0';
            }
            this.LsportsApi.getEvents(this.startDateApi, this.endDateApi, this.sportsModel.id, this.inOfferModel.Id, true, searchOfferNumber, this.searchName)
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                    this.showFixturesTable = true;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showFixturesTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        getSports() {
            this.showNoEvents = false;
            this.sports = [];
            this.LsportsApi.getSports(this.startDateApi, this.endDateApi, true)
                .then((response) => {
                    this.sports = response.data;
                    this.filtersInitializationDone = true;
                    this.sports.unshift(this.defaultSportsModel);
                    this.sportsModel = this.defaultSportsModel;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showFixturesTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        clearFixturesTable() {
            this.items = [];
            this.showFixturesTable = false;
        },
        importFixtures() {
            this.showModal = false;
            if (!this.importInProgress) {
                this.importInProgress = true;
                this.showImportLoading = true;
                let fixturesObject = {
                    FixturesIds: this.selectedIds.join(),
                    FixturesActive: this.fixturesActive,
                };
                this.fixtures = [];
                this.BetradarApi.importFixtues(fixturesObject)
                    .then(() => {
                        let importedCount = this.selectedIds.length;
                        this.showImportLoading = false;
                        this.showFixturesTable = false;
                        this.showOutrightsTable = false;
                        this.selectedIds = [];
                        this.importInProgress = false;
                        if (this.fixturesActive) {
                            this.showToast('success', this.$tc('sportsbets.alerts.eventImported', { count: importedCount }));
                        } else {
                            this.showToast('success', this.$tc('sportsbets.alerts.outrightImported', { count: importedCount }));
                        }
                    })
                    .catch(() => {
                        this.showImportLoading = false;
                        this.showErrorImportAlert = true;
                        this.importInProgress = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            }
        },
        inOfferIcon(inOffer) {
            return inOffer == true ? 'check' : 'times';
        },
        inOfferColor(inOffer) {
            return inOffer == true ? 'success' : 'danger';
        },
        refresh() {
            this.expandedRows = [];
            this.readItems();
        },
        subscriptionError() {
            this.showToast('error', this.$t('alerts.liveSubscriptionError'));
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>
